<template lang="pug">
    authorized-layout
        template(v-slot:main-content)
            construction-block.mb-2
                tab-navigation(:items="navigationItems")
            router-view
</template>

<script>
//Components
import AuthorizedLayout from '@/layouts/authorized-layout';
import TabNavigation from '@/components/common/tab-navigation';
import ConstructionBlock from '@/components/common/block';
//Config
import { TABS_NAVIGATION } from '@/configs/names.js'

export default {
    name: "CategoriesPage",
    components: {
        'authorized-layout': AuthorizedLayout,
        'tab-navigation': TabNavigation,
        'construction-block': ConstructionBlock,
    },
    computed: {
        TABS_NAVIGATION() {
            return TABS_NAVIGATION
        },
        navigationItems() {
            return [
                { route: '/categories/list', name: this.TABS_NAVIGATION.list },
            ]
        }
    }
}
</script>